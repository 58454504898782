// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-album-tsx": () => import("./../../../src/pages/album.tsx" /* webpackChunkName: "component---src-pages-album-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photo-tsx": () => import("./../../../src/pages/photo.tsx" /* webpackChunkName: "component---src-pages-photo-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-psychology-tsx": () => import("./../../../src/pages/psychology.tsx" /* webpackChunkName: "component---src-pages-psychology-tsx" */),
  "component---src-pages-test-albums-tsx": () => import("./../../../src/pages/test/albums.tsx" /* webpackChunkName: "component---src-pages-test-albums-tsx" */),
  "component---src-pages-test-one-image-js": () => import("./../../../src/pages/test/one-image.js" /* webpackChunkName: "component---src-pages-test-one-image-js" */),
  "component---src-pages-test-tags-tsx": () => import("./../../../src/pages/test/tags.tsx" /* webpackChunkName: "component---src-pages-test-tags-tsx" */)
}

